import { Link } from "react-router-dom";
import ILG from '../images/15ILG-MMG-IG-FLYER.png'

export default function Main() {
    return (
     <div className="md:container md:mx-auto md:min-h-screen">
            <div className="md:grid md:grid-cols-2">
                <div className="text-sm p-2 text-pink font-quicksand md:text-xl md:p-5">  
                    <h2 className="animate-bounce md:text-2xl">CERTIFIED SPEAKERS, TRAINERS & COACHES.</h2>
                    <h2 className="text-4xl font-greatvibes pt-5 md:text-5xl lg:text-6xl">Welcome </h2>
                    <p className="pt-5">At <span className="font-yeseva md:text-xl">LIVE A BETTER LIFE 24•7</span>, we are dedicated to helping individuals create personal brands that empower them to achieve their professional and personal goals. By providing resources and guidance, we support both men and women in developing brands that are not only professional and polished but also deeply authentic and reflective of their true selves. This approach enables them to stand out and connect meaningfully in their personal and professional environments.</p> 
                </div> 
                <div className='flex justify-center p-10  '>
                    <Link to='/trainings'><img src={ILG} width={300} alt="15ILG 25" className="rounded-lg" /></Link>
                </div>
            </div>
     </div>
    )
}