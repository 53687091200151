import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//Components
import Navbar from "./Components/NavBar.jsx"
import Footer from "./Components/Footer.js"
import Home from "./Pages/Home.js"
import Contact from "./Components/Contact.js"
import AboutUs from "./Pages/AboutUs.js";
import Trainings from "./Pages/Trainings.js"
import Donate from "./Pages/Donate.js";
import { LoginPage } from "./Pages/LoginPage.js";
import Branding from "./Components/Branding/Branding.js";
import Coaching from "./Pages/Coaching.js";
import Speaking from "./Pages/Speaking.jsx";
import ServicesListing from "./Pages/ServicesListing.js";
import SignUp from "./Components/SignUp.js";
import Register15Laws from "./Components/Training/Register15Laws.js";
import CoachingSignUp from "./Components/Coaching/CoachingSignUp.jsx";
import Thankyou from "./Components/Coaching/Thankyou.jsx";
import Booking from "./Components/Speaking/Booking.jsx";
import ScrollToTop from "./Components/ScrollToTop.jsx";
import DiscMethod from "./Pages/DiscMethod.jsx";
import DreamDay from "./Components/Events/DreamDay.jsx";
import Freebies from "./Components/Freebies/Freebies.jsx";

export default function App() {
    return (
      <div>
       
        <Router>
          <ScrollToTop />
          <Navbar />
               <Routes>  
                <Route path="/" element={<Home />} />
                <Route path="/AboutUs" element={<AboutUs />} />
                <Route path="/Services" element={<ServicesListing />} />
                <Route path="/Trainings" element={<Trainings />} />
                <Route path="/Speaking" element={<Speaking />} />
                <Route path="/Coaching" element={<Coaching />} />
                <Route path="/SignUp" element={<SignUp />} />
                <Route path="/Branding" element={<Branding />} />
                <Route path="/Contact" element={<Contact />} /> 
                <Route path="/LoginPage" element={<LoginPage />} />
                <Route path="/Donate" element={<Donate />} />            
                <Route path="/Register" element={<Register15Laws />} />
                <Route path="/CoachingSignUp" element={<CoachingSignUp />} />
                <Route path="/thankyou" element={<Thankyou />} />
                <Route path="/booking" element={<Booking />} />
                <Route path="/discmethod" element={<DiscMethod />} />
                <Route path="/DreamDay" element={<DreamDay />} />
                <Route path='/freebees' element={<Freebies />} />
            </Routes>
            <Footer />                
         </Router>
         
      </div> 
    )
}