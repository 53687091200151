import Main from "../Components/Main"
import WhyLABL from "../Components/WhyLABL"
import YTCard from "../Components/YTCard"
import ServicesListing from "./ServicesListing"
import backgroundImage from "../images/office_bg.png"

import LABLMission from "../Components/LABLMission"
import EventMain from "../Components/EventMain"

export default function Home() {
    return (
      <div >
               <div className="bg-cover bg-no-repeat" style={{ backgroundImage: `url(${backgroundImage})` }}>
                  <Main />
                </div> 
                
                <ServicesListing />
                <div className="hidden bg-cover bg-no-repeat md:block"><WhyLABL /></div>
                {/* <YTCard embedId="pf9QX5D_oYM"/> */} 
       </div>
    )
}