import AboutCoaches from "./AboutCoaches";
import { Link } from "react-router-dom";


export default function CoachingPackages() {
    return (

      <div className='p-2 mb-2 text-white font-quicksand'>
         <h1 className="font-greatvibes text-3xl text-center md:pt-10 md:text-5xl">The Level of Coaching Support</h1>
     
        <div className='md:mx-auto '>
          <h1 className='text-xl text-center md:text-3xl'>Individual Coaching </h1>
            <div className="m-4 p-4 lg:gap-10 lg:m-5">
              <p className='text-lg'>When we engage in personalized coaching sessions with you, our utmost aim is to assist you in realizing your purpose, vision, and objectives by tailoring Maxwell Method of Coaching to your unique requirements. Collaboratively, we'll devise a potent, strategic roadmap for you, fostering clarity through consistent weekly sessions that encompass interaction and mutual accountability. </p>
              <p className='text-center pt-5 text-xl'>Cost: $800 (6 Sessions) OR <section>$1500 (12 Sessions)</section></p>
              <p className='text-center pt-3 text-xl text-pink'>-Workbook Included-</p>
             <div className="flex justify-center">
              <button className='mt-4 px-7 py-3 text-md font-medium text-center text-dk-blue bg-white rounded-3xl hover:bg-pink hover:text-dk-blue focus:ring-4 focus:outline-none focus:ring-dk-blue dark:bg-pink dark:hover:bg-dk-blue dark:focus:ring-pink'> <Link to="/coachingSignUp">Register</Link></button>
            </div>
        </div> 
     
          {/* <div className='mx-auto font-quicksand'>
          <h1 className='text-lg text-center'>Group Coaching</h1>
            <div className="m-4 p-4 lg:gap-10 lg:m-5">
              <p className='text-md'>When I engage with a group – while it's not individual-focused – harnessing the collective insights amplifies the depth of discussions and yields solutions surpassing individual capabilities. This cohesive unity fosters personal leadership growth and enables each member to flourish in a supportive environment that nurtures the entire group. It's an unparalleled experience akin to an academic think tank, fostering innovation and collaboration. </p>
             <button className='mt-4 inline-flex items-center px-7 py-3 text-sm font-medium text-center text-dk-blue bg-white rounded-3xl hover:bg-pink hover:text-dk-blue focus:ring-4 focus:outline-none focus:ring-dk-blue dark:bg-pink dark:hover:bg-dk-blue dark:focus:ring-pink'> <Link to="/coachingSignUp">Sign Up</Link></button>
           
            </div> 
        </div> */}
        </div>
    </div>    
      
    )
}